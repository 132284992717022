import { isNil } from 'lodash'
import useCanReadProjectAssignmentList from '~/projects_assignments/authorization/useCanReadProjectAssignmentList'

interface HookResult {
  loading: boolean
  error?: Error
  defaultPagePath?: string
}

export default function useGetLoggedInUserDefaultPage (options?: { skip?: boolean }): HookResult {
  const {
    loading: loadingReadProjectAssignmentList,
    error: canReadProjectAssignmentListError,
    isAuthorized: isReadProjectAssignmentListAuthorized
  } = useCanReadProjectAssignmentList(options)

  const defaultRedirectUrl = localStorage.getItem('defaultRedirectUrl')
  if (!isNil(defaultRedirectUrl)) return { loading: false, defaultPagePath: defaultRedirectUrl }

  const personId = localStorage.getItem('personId')
  if (!isNil(personId)) return { loading: false, defaultPagePath: `/profile/${personId}` }

  if (options?.skip ?? false) { return { loading: false } }

  return {
    loading: loadingReadProjectAssignmentList,
    error: canReadProjectAssignmentListError,
    defaultPagePath: isReadProjectAssignmentListAuthorized
      ? '/project-assignment/list'
      : '/ats/list'
  }
}

export function getSimpleRedirectPath (): string {
  const personId = localStorage.getItem('personId')

  return !isNil(personId)
    ? `/profile/${personId}`
    : '/ats/list'
}

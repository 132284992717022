import { isFunction, isUndefined } from 'lodash-es'
import { ReactElement, ReactNode } from 'react'
import useAuthorization from '~/main_app/hooks/useAuthorization'
import { Action } from '~/service_providers/authorization/actions'
import { Validator } from '~/service_providers/authorization/validator'

interface AuthorizationProps {
  action?: Action
  validators: Validator[]
  onAuthorizationAccepted?: () => void
  onAuthorizationRejected?: (action: Action | undefined, validator: Validator | undefined) => void
  onError?: (error: Error) => void
  authorizationRejectedFallback?: ReactElement
  errorFallback?: ReactElement
  loadingFallback?: ReactElement
  children: ReactNode | ((args: ReturnType<typeof useAuthorization>) => ReactElement)
}

export default function Authorization (props: AuthorizationProps): ReactElement {
  const {
    action,
    validators,
    loadingFallback,
    authorizationRejectedFallback,
    errorFallback,
    children,
    onAuthorizationAccepted,
    onAuthorizationRejected,
    onError
  } = props

  const useAuthorizationResult = useAuthorization({
    action,
    validators,
    onAuthorizationAccepted,
    onAuthorizationRejected,
    onError
  })

  if (useAuthorizationResult.loading && (!isUndefined(loadingFallback) || !isFunction(children))) {
    return <>{loadingFallback}</>
  }

  if (!isUndefined(useAuthorizationResult.error) && (!isUndefined(errorFallback) || !isFunction(children))) {
    return <>{errorFallback}</>
  }

  if (!useAuthorizationResult.isAuthorized && (!isUndefined(authorizationRejectedFallback) || !isFunction(children))) {
    return <>{authorizationRejectedFallback}</>
  }

  return isFunction(children) ? children(useAuthorizationResult) : <>{children}</>
}

import { isFunction } from 'lodash'
import isNil from 'lodash/isNil'
import Router from 'next/router'
import { ComponentType, ReactElement, useCallback } from 'react'
import { toast } from 'react-toastify'
import guards from '~/service_providers/authorization/guards'
import useTranslate from '../../service_providers/i18n/useTranslate'
import Authorization from '../components/Authorization'
import { getSimpleRedirectPath } from '../hooks/useGetLoggedInUserDefaultPage'

export interface WithRequiredAuthenticationOptions {
  invertCondition?: boolean
  redirectPath?: string
}

type UseAuthenticationConfiguration = (
  () => { loading: boolean, error?: Error, configuration?: WithRequiredAuthenticationOptions }
)

export const withRequiredAuthentication = <WrappedProps extends object>(
  WrappedComponent: ComponentType<WrappedProps>,
  optionsOrConfigurationHook?: WithRequiredAuthenticationOptions | UseAuthenticationConfiguration
): (props: WrappedProps) => ReactElement => {
  const useAuthenticationConfiguration: UseAuthenticationConfiguration = () => {
    if (isFunction(optionsOrConfigurationHook)) {
      return optionsOrConfigurationHook()
    } else {
      return { configuration: { ...optionsOrConfigurationHook }, loading: false }
    }
  }

  const WithAuthentication = (props: WrappedProps): ReactElement => {
    const { translate } = useTranslate()
    const { loading, error, configuration } = useAuthenticationConfiguration()

    const redirectToLogIn = useCallback(() => {
      if (loading) return

      if (!isNil(error)) {
        toast.error(translate('An error occurred trying to get authentication configuration'))
        // eslint-disable-next-line no-console
        Router.push('/auth/login').catch(console.error)
      }

      Router.push(configuration?.redirectPath ?? (configuration?.invertCondition as boolean ? getSimpleRedirectPath() : '/auth/login'))
        // eslint-disable-next-line no-console
        .catch(console.error)
    }, [configuration?.redirectPath, error, loading, translate, configuration?.invertCondition])

    return (
      <Authorization
        action='view_authenticated_content'
        validators={[configuration?.invertCondition === true ? guards.not.userLoggedIn : guards.userLoggedIn]}
        onAuthorizationRejected={redirectToLogIn}
      >
        <WrappedComponent {...props} />
      </Authorization>
    )
  }

  WithAuthentication.displayName = `WithRequiredAuthentication(${WrappedComponent.displayName ?? ''})`

  return WithAuthentication
}

import { useMemo } from 'react'

import { WithRequiredAuthenticationOptions } from '~/main_app/hoc/withRequiredAuthentication'
import { useFlag } from '~/service_providers/feature_flag'
import useAuthenticationProvider from '../useAuthenticationProvider'
import useGetLoggedInUserDefaultPage from '../useGetLoggedInUserDefaultPage'

interface HookResult {
  loading: boolean
  error?: Error
  configuration?: { invertCondition?: boolean, redirectPath?: string }
}

export default function useHomePageConfiguration (): HookResult {
  const { loading: loadingProvider, error: authenticationProviderError, authenticationProvider } = useAuthenticationProvider()
  const isLoggedIn = useMemo(() => authenticationProvider?.isLoggedIn() ?? false, [authenticationProvider])
  const isUserAnonymous = useMemo(() => authenticationProvider?.isUserAnonymous() ?? false, [authenticationProvider])
  const { loading: loadingFlag, value: IS_TRY_IT_NOW_PAGE_DEFAULT_PAGE } = useFlag('IS_TRY_IT_NOW_PAGE_DEFAULT_PAGE', false)
  const { loading: loadingGetLoggedInUserDefaultPage, error: getLoggedInUserDefaultPageError, defaultPagePath } = useGetLoggedInUserDefaultPage({ skip: !isLoggedIn })

  let configuration: WithRequiredAuthenticationOptions = {
    redirectPath: IS_TRY_IT_NOW_PAGE_DEFAULT_PAGE ? '/try-it-now' : undefined
  }

  if (isLoggedIn && isUserAnonymous) {
    configuration = { invertCondition: true, redirectPath: '/ats/list' }
  }

  if (isLoggedIn && !isUserAnonymous) {
    configuration = { invertCondition: true, redirectPath: defaultPagePath }
  }

  return {
    loading: loadingFlag || loadingProvider || loadingGetLoggedInUserDefaultPage,
    error: authenticationProviderError ?? getLoggedInUserDefaultPageError,
    configuration
  }
}

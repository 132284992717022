import { ReactElement } from 'react'

import { withRequiredAuthentication } from '~/main_app/hoc/withRequiredAuthentication'
import useHomePageConfiguration from '~/main_app/hooks/useHomePageConfiguration'

function Home (): ReactElement {
  return <></>
}

export default withRequiredAuthentication(Home, useHomePageConfiguration)
